import React, { lazy } from 'react';

import DefaultLayout from '../layout/Default';

const LeagueScreen = lazy(() => import('../screens/League'));
const Leagues = lazy(() => import('../screens/Leagues'));
const LeagueRules = lazy(() => import('../screens/LeagueRules'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
  layout: DefaultLayout,
});

const leagueRoutes = [
  {
    path: '/leagues/:game',
    element: () => <Leagues />,
  },
  {
    path: '/past-leagues/:game',
    element: () => <Leagues />,
  },
  {
    path: '/leagues/:game/:code',
    element: () => <LeagueScreen />,
  },
  {
    path: '/leagues/:game/:code/rules',
    element: () => <LeagueRules />,
  },
];

const mappedLeagueRoutes = leagueRoutes.map(addCommonProps);

export default mappedLeagueRoutes;
