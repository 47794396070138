import React from 'react';

import { CreditsProvider } from './CreditsProvider';
import { GamesProvider } from './GamesProvider';

const Contexts = ({ children }) => (
  <GamesProvider>
    <CreditsProvider>{children}</CreditsProvider>
  </GamesProvider>
);

export default Contexts;
