import React, { createContext, useCallback, useMemo, useState } from 'react';

export const GamesContext = createContext(null);

export const GamesProvider = ({ children }) => {
  const [games, setGames] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);

  const updateCurrentGame = useCallback((data) => {
    setCurrentGame(data);
  }, []);

  const updateGames = useCallback((data) => {
    setGames(data);
  }, []);

  const contextValue = useMemo(
    () => ({
      games,
      updateGames,
      currentGame,
      updateCurrentGame,
    }),
    [games, updateGames, currentGame, updateCurrentGame]
  );

  return (
    <GamesContext.Provider value={contextValue}>
      {children}
    </GamesContext.Provider>
  );
};
