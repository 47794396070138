import React, { lazy } from 'react';

import DefaultLayout from '../layout/Default';

const Profile = lazy(() => import('../screens/Profile'));
const MySales = lazy(() => import('../screens/MySales'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
  layout: DefaultLayout,
});

const profileRoutes = [
  { path: '/profile', element: () => <Profile /> },
  { path: '/subscriptions', element: () => <Profile /> },
  { path: '/purchases', element: () => <Profile /> },
  { path: '/history', element: () => <Profile /> },
  { path: '/my-sales', element: () => <MySales /> },
];

const mappedProfileRoutes = profileRoutes.map(addCommonProps);

export default mappedProfileRoutes;
