import React, { useContext, useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { CiStreamOff, CiStreamOn } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';

import useCurrentGame from '@hooks/localStorage/useCurrentGame';
import { fifaCoach } from '@resources/config';
import { GamesContext } from '@src/contexts/GamesProvider';
import useUserSelector from '@src/store/selectors/useUserSelector';

import {
  Footer,
  Menu,
  MenuItem,
  MenuItemLabel,
  StyledCourseIcon,
  StyledEvolutionIcon,
  StyledHomeIcon,
  StyledLeagueIcon,
} from './style';

const ApplicationFooterMobile = () => {
  const navigate = useNavigate();
  const { user } = useUserSelector();
  const userId = user?._id;
  const { currentGame, isEfootball, isLol, isValorant, isFifa, isWarzone } =
    useCurrentGame();
  const [currentGameInfo, setCurrentGameInfo] = useState();
  const { games } = useContext(GamesContext);

  useEffect(() => {
    if (currentGame) {
      setCurrentGameInfo(games?.find((game) => game.codeName === currentGame));
    }
  }, [currentGame]);

  const path = window.location.pathname;
  const isMenuActive = (slugs) => slugs.some((slug) => path.includes(slug));

  const renderMenuItems = () => {
    if (isLol) {
      return (
        <>
          <MenuItem
            onClick={() => {
              navigate('/home');
            }}
          >
            <StyledHomeIcon active={isMenuActive(['home'])} />
            <MenuItemLabel active={isMenuActive(['home'])}>Home</MenuItemLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(`/analysis/${currentGame}/${userId}`);
            }}
          >
            <StyledEvolutionIcon active={isMenuActive(['analysis'])} />
            <MenuItemLabel active={isMenuActive(['analysis'])}>
              Evolução
            </MenuItemLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/classes');
            }}
          >
            <StyledCourseIcon active={isMenuActive(['classes'])} />
            <MenuItemLabel active={isMenuActive(['classes'])}>
              Cursos
            </MenuItemLabel>
          </MenuItem>
        </>
      );
    }
    if (isFifa) {
      return (
        <>
          <MenuItem
            width={80}
            onClick={() => {
              navigate('/home');
            }}
          >
            <StyledHomeIcon active={isMenuActive(['home'])} />
            <MenuItemLabel active={isMenuActive(['home'])}>Home</MenuItemLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/classes');
            }}
          >
            <StyledCourseIcon active={isMenuActive(['classes'])} />
            <MenuItemLabel active={isMenuActive(['classes'])}>
              Elite Milionária
            </MenuItemLabel>
          </MenuItem>
          {currentGameInfo?.live?.on ? (
            <MenuItem
              onClick={() => {
                navigate('/live');
              }}
            >
              <IconContext.Provider value={{ color: '#3BE529', size: '21px' }}>
                <CiStreamOn />
              </IconContext.Provider>

              <MenuItemLabel active={isMenuActive(['live'])} fontSize={10}>
                Aula Ao Vivo
              </MenuItemLabel>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                navigate(fifaCoach);
              }}
            >
              <StyledEvolutionIcon active={isMenuActive(['coaches'])} />
              <MenuItemLabel active={isMenuActive(['coaches'])}>
                Análise Times
              </MenuItemLabel>
            </MenuItem>
          )}
        </>
      );
    }
    if (isValorant) {
      return (
        <>
          <MenuItem
            width={80}
            onClick={() => {
              navigate('/home');
            }}
          >
            <StyledHomeIcon active={isMenuActive(['home'])} />
            <MenuItemLabel active={isMenuActive(['home'])} fontSize={11}>
              Home
            </MenuItemLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate(`/analysis/${currentGame}/${userId}`);
            }}
          >
            <StyledEvolutionIcon active={isMenuActive(['analysis'])} />
            <MenuItemLabel active={isMenuActive(['analysis'])} fontSize={11}>
              Evolução
            </MenuItemLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/classes');
            }}
          >
            <StyledCourseIcon active={isMenuActive(['classes'])} />
            <MenuItemLabel active={isMenuActive(['classes'])} fontSize={11}>
              Valorant M.E.T.A
            </MenuItemLabel>
          </MenuItem>
        </>
      );
    }
    if (isEfootball) {
      return (
        <>
          <MenuItem
            width={80}
            onClick={() => {
              navigate('/home');
            }}
          >
            <StyledHomeIcon active={isMenuActive(['home'])} />
            <MenuItemLabel active={isMenuActive(['home'])} fontSize={11}>
              Home
            </MenuItemLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/classes');
            }}
          >
            <StyledCourseIcon active={isMenuActive(['classes'])} />
            <MenuItemLabel active={isMenuActive(['classes'])} fontSize={11}>
              eGol Academy
            </MenuItemLabel>
          </MenuItem>
        </>
      );
    }
    if (isWarzone) {
      return (
        <>
          <MenuItem
            width={80}
            onClick={() => {
              navigate('/home');
            }}
          >
            <StyledHomeIcon active={isMenuActive(['home'])} />
            <MenuItemLabel active={isMenuActive(['home'])} fontSize={10}>
              Home
            </MenuItemLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/classes');
            }}
          >
            <StyledCourseIcon active={isMenuActive(['classes'])} />
            <MenuItemLabel active={isMenuActive(['classes'])} fontSize={10}>
              Conteúdos 9-OPS
            </MenuItemLabel>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate('/leagues/WARZONE');
            }}
          >
            <StyledLeagueIcon active={isMenuActive(['leagues'])} />
            <MenuItemLabel active={isMenuActive(['leagues'])} fontSize={11}>
              Torneios
            </MenuItemLabel>
          </MenuItem>
        </>
      );
    }
    return (
      <>
        <MenuItem
          width={80}
          onClick={() => {
            navigate('/home');
          }}
        >
          <StyledHomeIcon active={isMenuActive(['home'])} />
          <MenuItemLabel active={isMenuActive(['home'])} fontSize={10}>
            Home
          </MenuItemLabel>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/classes');
          }}
        >
          <StyledCourseIcon active={isMenuActive(['classes'])} />
          <MenuItemLabel active={isMenuActive(['classes'])} fontSize={10}>
            Conteúdos 9-OPS
          </MenuItemLabel>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate('/live');
          }}
        >
          {currentGameInfo?.live?.on ? (
            <IconContext.Provider value={{ color: '#3BE529', size: '21px' }}>
              <CiStreamOn />
            </IconContext.Provider>
          ) : (
            <IconContext.Provider
              value={
                isMenuActive(['live'])
                  ? { color: '#3BE529', size: '21px' }
                  : { color: '#ffffff', size: '21px' }
              }
            >
              <CiStreamOff />
            </IconContext.Provider>
          )}

          <MenuItemLabel active={isMenuActive(['live'])} fontSize={10}>
            Aula Ao Vivo
          </MenuItemLabel>
        </MenuItem>
      </>
    );
  };

  return (
    <Footer removePl={isFifa} isLogged={!!userId}>
      <Menu>{renderMenuItems()}</Menu>
    </Footer>
  );
};

export default ApplicationFooterMobile;
