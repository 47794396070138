import requester from '../resources/requester';
import Mixpanel from '../utils/mixpanel.utils';
import { oauthTypes } from '../utils/oauth.utils';
import {
  getActiveSubscriptions,
  getMainGame,
} from '../utils/subscription.utils';
import setToast from '../utils/toast.utils';
import { setAuthAndUser } from './utils.action';

export const signIn = async (
  store,
  navigate,
  credentials,
  oauthType,
  page,
  setCurrentGame
) => {
  let endpoint = '/v1/public/auth/signin';

  if (oauthType) {
    switch (oauthType) {
      default:
      case oauthTypes.DISCORD: {
        endpoint = '/v1/public/auth/signin/discord';
        break;
      }
      case oauthTypes.GOOGLE: {
        endpoint = '/v1/public/auth/signin/google';
        break;
      }
      case oauthTypes.TWITCH: {
        endpoint = '/v1/public/auth/signin/twitch';
        break;
      }
    }
  }

  const [error, response] = await requester('POST', endpoint, credentials);
  if (error) {
    setToast(
      'error',
      oauthType
        ? 'Usuário não cadastrado ou esta conta não está vinculada!'
        : 'Usuário ou senha incorretos!'
    );
  } else {
    const { token, user } = response.data;
    const isSubscriber = getActiveSubscriptions(user)?.length > 0;

    Mixpanel().identify(user.profile.email);
    await requester('POST', '/v1/public/tracker', {
      eventName: 'Login',
      people: {
        username: user.profile.username,
        email: user.profile.email,
        subscriber: isSubscriber,
      },
      params: {
        username: user.profile.username,
        id: user._id,
      },
    });

    let gameName = setAuthAndUser(store, token, user);

    const game =
      window.location.pathname.split('/').filter((r) => r !== '').length === 2
        ? window.location.pathname.split('/')[
            window.location.pathname.split('/').length - 1
          ]
        : '';
    if (user.subscription?.length && !game) {
      gameName = getMainGame(user.subscription);
    } else if (game) {
      gameName = game.toUpperCase();
    } else if (!user.gaming?.lol?.summoner) {
      if (user.gaming?.valorant?.gameTag) {
        gameName = 'VALORANT';
      }
      if (user.gaming?.fifa?.gameTag) {
        gameName = 'FIFA';
      }
      if (user.gaming?.warzone?.gameTag) {
        gameName = 'WARZONE';
      }
    }

    setCurrentGame(gameName);

    if (page) navigate(`/${page}`);
    else navigate('/home');
  }
};

export const signUp = async (
  store,
  navigate,
  params,
  game,
  ads,
  oauthType,
  page
) => {
  store.actions.cleanStorage();
  let result;

  if (oauthType) {
    switch (oauthType) {
      default:
      case oauthTypes.DISCORD: {
        result = await requester('POST', '/v1/public/auth/signup/discord', {
          code: params.code,
          game,
        });
        break;
      }
      case oauthTypes.GOOGLE: {
        result = await requester('POST', '/v1/public/auth/signup/google', {
          code: params.code,
          game,
        });
        break;
      }
      case oauthTypes.TWITCH: {
        result = await requester('POST', '/v1/public/auth/signup/twitch', {
          code: params.code,
          game,
        });
        break;
      }
    }
  } else {
    result = await requester('POST', '/v1/public/auth/signup', {
      profile: params.profile,
      game,
    });
  }
  const resultData = result[0] || result[1];
  const response = resultData.response ? resultData.response : resultData;

  switch (response.status) {
    case 201:
    case 200: {
      const { token, user } = response.data;
      const isSubscriber = getActiveSubscriptions(user)?.length > 0;

      Mixpanel().identify(user.profile.email);
      await requester('POST', '/v1/public/tracker', {
        eventName: 'Signup',
        people: {
          username: user.profile.username,
          email: user.profile.email,
          subscriber: isSubscriber,
          ads: !!ads,
        },
        params: {
          username: user.profile.username,
          id: user._id,
          affiliate: params.affiliate,
          game,
        },
      });

      setAuthAndUser(store, token, user, game);
      if (page) navigate(`/${page}`);
      else if (game) navigate(`/gaming/${game}`);
      else navigate('/gaming');

      break;
    }
    case 400: {
      if (response?.data?.data?.error?.message?.split(' ')?.includes('Email')) {
        setToast('error', 'Email já cadastrado');
      } else if (
        response?.data?.data?.error?.message?.split(' ')?.includes('Username')
      ) {
        setToast('error', 'Nome de usuário já cadastrado');
      } else if (
        response?.data?.data?.error?.message?.split(' ')?.includes('Discord')
      ) {
        setToast('error', 'Discord já vinculado a outra conta');
      } else if (
        response?.data?.data?.error?.message?.split(' ')?.includes('Google')
      ) {
        setToast('error', 'Conta Google já vinculada a outra conta');
      } else {
        setToast('error', 'Erro no cadastro');
      }
      break;
    }
    default:
    case 500: {
      setToast('error', 'Erro no cadastro');
      break;
    }
  }
};

export const forgotPassword = async (store, email) => {
  const [error] = await requester('POST', '/v1/public/auth/forgot-password', {
    email,
  });
  if (error) {
    setToast('error', 'Erro interno');
  } else {
    setToast('success', 'Email enviado');
  }
};

export const resetPassword = async (store, navigate, { token, password }) => {
  const resetPasswordPayload = {
    token,
    password,
  };
  const [error] = await requester(
    'POST',
    '/v1/public/auth/reset-password',
    resetPasswordPayload
  );
  if (error) {
    setToast('error', 'Erro interno');
  } else {
    setToast('success', 'passwordUpdated');
    navigate('/signin');
  }
};

export const logOut = (store, navigate) => {
  store.actions.cleanStorage();
  navigate('/signin');
};
