import React from 'react';
import LoaderSpinnerComponent from 'react-loader-spinner';

import { Color } from '@styles/variables';

const LoaderSpinner = ({
  width = 80,
  height = 80,
  className = '',
  style = {},
}) => (
  <LoaderSpinnerComponent
    type="ThreeDots"
    color={Color.primary.main}
    height={height}
    width={width}
    className={className}
    style={style}
  />
);

export default LoaderSpinner;
