export const Color = {
  blue: {
    4: '#00474f',
    5: '#002329',
    6: '#1890FF',
  },
  gray: {
    1: '#fff',
    2: '#fafafa',
    3: '#f5f5f5',
    4: '#d6d6d6',
    5: '#d9d9d9',
    6: '#bfbfbf',
    7: '#8C8C8C',
    8: '#595959',
    9: '#262626',
    10: '#0E0E0E',
    11: '#000000',
    12: '#222222',
    13: '#eaeaea',
    14: '#4e4e4e',
    15: '#2c2c2c',
    16: '#1a1a1a',
  },
  gold: {
    6: '#faad14',
  },
  primary: {
    main: '#3BE529',
    light: '#52C41A',
  },
  lime: {
    1: '#FCFFE6',
    11: '#8dff35',
    12: '#6fff01',
    13: '#6FFF00',
  },
  sunsetOrange: {
    3: '#FFD591',
    6: '#FA8C16',
    11: '#DFA845',
  },
  yellow: {
    5: '#FFC53D',
  },
  volcano: {
    1: '#FFF2E8',
    2: '#FFD8BF',
    3: '#FFBB96',
    4: '#FF9C6E',
    6: '#FA541C',
  },
  red: {
    1: '#fff1f0',
    6: '#F5222D',
    7: '#CF1322',
    8: '#FF4D4F',
  },
  purple: {
    1: '#24073F',
    2: '#1d0a2e',
    3: '#ca96ff',
    10: '#120338',
  },
  green: {
    8: '#389E0D',
  },
};

export const Device = {
  TABLET: '768px',
  DESKTOP: '1140px',
  LARGE_DESKTOP: '1536px',
};

export const MediaQuery = {
  MOBILE: '(max-width: 767px)',
  MINI_MOBILE: '(max-width: 767px) and (max-height: 620px)',
  SMALL_MOBILE: '(max-width: 300px)',
  LARGE_MOBILE_UP: '(min-height: 692px)',
  LARGE_MOBILE_DOWN: '(max-height: 692px)',
  TABLET_DOWN: '(max-width: 1139px)',
  TABLET_UP: '(min-width: 1140px)',
  LARGE_MOBILE_WIDTH_UP: '(min-width: 640px)',
  LARGE_MOBILE_WIDTH_DOWN: '(max-width: 639px)',
  SMALL_TABLET_WIDTH_UP: '(min-width: 820px)',
  SMALL_TABLET_WIDTH_DOWN: '(max-width: 820px)',
  SMALL_TABLET_WIDTH_RANGE_1: '(min-width: 820px) and (max-width: 950px)',
  LARGE_TABLET_WIDTH_DOWN: '(max-width: 899px)',
  LARGE_TABLET_WIDTH_UP: '(min-width: 900px)',
  DESKTOP_DOWN: '(max-width: 1535px)',
  DESKTOP_UP: '(min-width: 1140px)',
  LARGE_DESKTOP: '(min-width: 1536px)',
  MEDIUM_DESKTOP: '(min-width: 800px)',
  UP_MEDIUM_DESKTOP: '(max-width: 800px)',
  SMALL_MEDIUM_DESKTOP: '(min-width: 500px)',
  UP_SMALL_MEDIUM_DESKTOP: '(max-width: 500px)',
  SMALL_PADDING_MEDIUM_DESKTOP: '(min-width: 800px)',
  SMALL_DESKTOP_UP: '(min-width: 1055px)',
  SMALL_DESKTOP_DOWN: '(max-width: 1055px)',
  MEDIUM_DESKTOP_UP: '(min-width: 1250px)',
  MEDIUM_DESKTOP_DOWN: '(max-width: 1250px)',
  LARGE_DESKTOP_UP: '(min-width: 1600px)',
  SMALL_SMALL_MOBILE: '(max-width: 279px)',
  MEDIUM_MOBILE: '(max-width: 400px)',
};

export const FontFamily = {
  POPPINS: 'Poppins, Arial, Helvetica, sans-serif',
  SOURCE_SANS_PRO: 'Source Sans Pro, Arial, Helvetica, sans-serif',
  RALEWAY: 'Raleway, Arial, Helvetica, sans-serif',
  RADLEY: 'Radley, Arial, Helvetica, sans-serif',
  HYEON: 'Do Hyeon, Arial, Helvetica, sans-serif',
  QUICKSAND: 'Quicksand, Arial, Helvetica, sans-serif',
  ANTON: 'Anton, Arial, Helvetica, sans-serif',
};

export const FontWeight = {
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
};
