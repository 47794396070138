import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useCurrentGame from '@hooks/localStorage/useCurrentGame';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {
  fifaCoach,
  izziLolCoachUrl,
  izziValorantCoachUrl,
} from '@resources/config';
import requester from '@resources/requester';
import { GamesContext } from '@src/contexts/GamesProvider';
import { gamesEnum } from '@utils/games.utils';
import setToast from '@utils/toast.utils';

import IconLol from '../../../../assets/icons/Lol.svg';
import {
  Container,
  ContainerDrop,
  IconExpandLess,
  IconExpandMore,
  IconGame,
  Menu,
  OptionMenu,
  Title,
  TitleGameMenu,
} from './style';

const SelectGame = ({ userId }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const { currentGame, setCurrentGame } = useCurrentGame();
  const { games, updateGames } = useContext(GamesContext);

  const fetchGame = async () => {
    const [error, response] = await requester('GET', `/v1/public/games`);
    if (error) {
      setToast('error', 'Erro ao carregar games.');
    } else {
      const { data } = response;
      updateGames(data);
    }
  };

  useEffect(() => {
    if (!games) {
      fetchGame();
    }
  }, []);

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const getGameImg = (game) =>
    games?.filter((g) => g.codeName?.toUpperCase() === game?.toUpperCase())[0]
      ?.logo ?? IconLol;

  const getGameName = (game) =>
    games?.filter((g) => g.codeName?.toUpperCase() === game?.toUpperCase())[0]
      ?.name ?? 'League of Legends';

  const settingGameOnStorage = (game) => {
    setOpen(false);

    if (currentGame?.toUpperCase() !== game?.toUpperCase()) {
      setCurrentGame(game);
      if (pathname.includes('classes')) {
        navigate('/classes');
        if (pathname === '/classes') {
          window.location.reload();
        }
      } else if (pathname.includes('/library/')) {
        navigate('/library');
      } else if (pathname.includes('izzi-build') && game !== gamesEnum.lol) {
        navigate('/home');
      } else if (pathname.includes('analysis')) {
        navigate(`/analysis/${game}/${userId}`);
        if (pathname === `/analysis/${game}/${userId}`) {
          window.location.reload();
        }
      } else if (pathname.includes('coaches')) {
        if (game.toLowerCase() === 'fifa') {
          navigate(fifaCoach);
          window.location.reload();
        } else if (game.toLowerCase() === 'valorant') {
          navigate(izziValorantCoachUrl);
          window.location.reload();
        } else if (game.toLowerCase() === 'lol') {
          navigate(izziLolCoachUrl);
          window.location.reload();
        } else {
          navigate('/home');
        }
      } else if (pathname.includes('leagues')) {
        navigate(`/${pathname.split('/')[1]}/${game}`);
        window.location.reload();
      } else {
        window.location.reload();
      }
    }
  };

  const handleClickOut = () => {
    setOpen(false);
  };

  const gameMenuItems = games?.map((e) => (
    <OptionMenu onClick={() => settingGameOnStorage(e.codeName)} key={e.id}>
      <IconGame src={e.logo} alt="Game icon" />
      <TitleGameMenu>{e.name}</TitleGameMenu>
    </OptionMenu>
  ));

  return (
    <Box>
      <Container
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClickOut}>
                <Menu>{gameMenuItems}</Menu>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Container>

      <Grid container justifyContent="center">
        <Button onClick={handleClick('bottom')}>
          <ContainerDrop>
            <img src={getGameImg(currentGame)} alt="Game icon" />
            <Title>{getGameName(currentGame)}</Title>

            {open ? <IconExpandLess /> : <IconExpandMore />}
          </ContainerDrop>
        </Button>
      </Grid>
    </Box>
  );
};
export default SelectGame;
