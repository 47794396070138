import React from 'react';

import FooterMobile from '@components/ApplicationFooterMobile';
import Header from '@components/ApplicationHeader';
import Footer from '@components/Footer';

import { Container, Content } from './style';

const DefaultLayout = ({ children }) => (
  <Container>
    <Header />
    <Content>
      {children}
      <Footer />
    </Content>
    <FooterMobile />
  </Container>
);

export default DefaultLayout;
