export const addDays = (dateToAdd, days) => {
  const date = new Date(dateToAdd);
  date.setDate(date.getDate() + days);
  return date;
};

export const addHours = (dateToAdd, hours) => {
  const date = new Date(dateToAdd);
  date.setHours(date.getHours() + hours);
  return date;
};

export const dayFormatter = (date) => `0${date.getDate()}`.slice(-2);

export const dayMonthFormatter = (date) => {
  const day = date.getDate();
  const monthIndex = date.getMonth();
  return `${`0${day}`.slice(-2)}/${`0${monthIndex + 1}`.slice(-2)}`;
};

export const monthYearFormatter = (date) => {
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return `${`0${monthIndex + 1}`.slice(-2)}/${`${year}`.slice(-2)}`;
};

export const dateFormatter = (dateToFormat) => {
  const date = new Date(dateToFormat);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  return `${`0${day}`.slice(-2)}/${`0${monthIndex + 1}`.slice(
    -2
  )}/${`${year}`.slice(-2)}`;
};

export const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isYesterday = (date) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const dateToCheck = new Date(date);

  dateToCheck.setHours(
    today.getHours(),
    today.getMinutes(),
    today.getSeconds(),
    today.getMilliseconds()
  );

  return dateToCheck.getTime() === yesterday.getTime();
};

export const isTomorrow = (date) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const dateToCheck = new Date(date);
  dateToCheck.setHours(
    today.getHours(),
    today.getMinutes(),
    today.getSeconds(),
    today.getMilliseconds()
  );

  return dateToCheck.getTime() === tomorrow.getTime();
};

export const dateTimeFormatter = (date) => {
  const current = new Date(date);
  const isDateToday = isToday(current);
  const isDateYesterday = isYesterday(current);
  const isDateTomorrow = isTomorrow(current);
  const dateFormatted = dateFormatter(current);

  let dateComposed = dateFormatted;
  if (isDateToday) dateComposed = 'Hoje';
  if (isDateYesterday) dateComposed = 'Ontem';
  if (isDateTomorrow) dateComposed = 'Amanhã';

  const hours = current.getHours();
  const minutes = current.getMinutes();
  const time = `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
  return `${dateComposed} às ${time}`;
};

export const timeFormatter = (date) => {
  const current = date instanceof Date ? date : new Date(date);

  const hours = current.getHours();
  const minutes = current.getMinutes();
  const time = `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
  return `${time}`;
};

export const dateComposedFormatter = (date) => {
  const current = new Date(date);
  const isDateToday = isToday(current);
  const isDateYesterday = isYesterday(current);
  const isDateTomorrow = isTomorrow(current);
  const dateFormatted = dateFormatter(current);

  let dateComposed = dateFormatted;
  if (isDateToday) dateComposed = 'Hoje';
  if (isDateYesterday) dateComposed = 'Ontem';
  if (isDateTomorrow) dateComposed = 'Amanhã';

  return `${dateComposed}`;
};

export const getDataNextThursday = (date) => {
  const data = new Date(date);
  data.setDate(data.getDate() + ((4 + 7 - data.getDay()) % 7 || 7));
  return data;
};

export const getDataNextAnalysisDay = (date) => {
  const data = new Date(date);
  data.setDate(data.getDate() + 7);
  return data;
};

export const getHasSevenDaysFromDate = (date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const today = new Date();
  const finalDate = new Date(date);
  const utc1 = Date.UTC(
    finalDate.getFullYear(),
    finalDate.getMonth(),
    finalDate.getDate()
  );
  const utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY) >= 7;
};

export const getHasXDaysFromDate = (date, X) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const today = new Date();
  const finalDate = new Date(date);
  const utc1 = Date.UTC(
    finalDate.getFullYear(),
    finalDate.getMonth(),
    finalDate.getDate()
  );
  const utc2 = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY) >= X;
};

export const getHourDifferenceByDates = (date1, date2) => {
  const _MS_PER_HOUR = 1000 * 60 * 60;
  const utc1 = Date.UTC(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate(),
    date1.getHours(),
    date1.getMinutes()
  );
  const utc2 = Date.UTC(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate(),
    date2.getHours(),
    date2.getMinutes()
  );

  return parseInt((utc2 - utc1) / _MS_PER_HOUR, 10);
};

export const getAgeFromDateString = (dateString) => {
  const dateOfBirth = new Date(
    `${dateString.split('/')[2]}-${dateString.split('/')[1]}-${
      dateString.split('/')[0]
    }T03:00:00.000Z`
  );
  const differenceInMsFromNow = Date.now() - dateOfBirth.getTime();
  const dateToGetAge = new Date(differenceInMsFromNow);
  const yearFromJsDateZero = new Date(0).getUTCFullYear();
  const yearToGetAge = dateToGetAge.getUTCFullYear();
  const absoluteAge = Math.abs(yearToGetAge - yearFromJsDateZero);

  return absoluteAge;
};

export const intervalTime = (dtStart, dtEnd) => {
  const diffMs = dtEnd - dtStart;
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
  let diff = '';
  if (diffMins > 0) {
    diff = `${diffHrs}h ${diffMins}m`;
  } else {
    diff = `${diffHrs}h`;
  }

  return diff;
};
