import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

import Popper from '@mui/material/Popper';
import styled from 'styled-components';

import { Color, FontFamily, FontWeight } from '../../../../styles/variables';

export const Container = styled(Popper)`
  z-index: 1;
  position: absolute;
`;

export const ContainerDrop = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 12px;
    height: 6px;
    margin-left: 12px;
  }

  img:first-child {
    width: 21px;
    height: 21px;
  }
`;

export const Title = styled.h2`
  font-size: 14px;
  font-family: ${FontFamily.POPPINS};
  font-style: normal;
  font-weight: normal;
  color: #bfbfbf;
  text-transform: none;

  margin-left: 13px;

  @media (max-width: 695px) {
    display: none;
  }
`;

export const Menu = styled.div`
  background: #262626;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 16px;

  a:first-child {
    margin-top: 10px;
  }

  a:last-child {
    margin-bottom: 0px;
  }
`;

export const OptionMenu = styled.a`
  display: flex;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const IconGame = styled.img`
  height: 21px;
  width: 21px;
  margin-right: 7px;
`;

export const TitleGameMenu = styled.h1`
  font-family: ${FontFamily.POPPINS};
  font-style: normal;
  font-weight: ${FontWeight.REGULAR};
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
`;

export const IconExpandLess = styled(RiArrowUpSLine)`
  font-size: 24px;
  color: ${Color.gray[1]};
`;
export const IconExpandMore = styled(RiArrowDownSLine)`
  font-size: 24px;
  color: ${Color.gray[1]};
`;
