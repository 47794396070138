import React, { createContext, useCallback, useMemo, useState } from 'react';

export const CreditsContext = createContext(null);

export const CreditsProvider = ({ children }) => {
  const [userCredits, setUserCredits] = useState(null);

  const updateUserCredits = useCallback((data) => {
    setUserCredits(data);
  }, []);

  const contextValue = useMemo(
    () => ({
      userCredits,
      updateUserCredits,
    }),
    [userCredits, updateUserCredits]
  );

  return (
    <CreditsContext.Provider value={contextValue}>
      {children}
    </CreditsContext.Provider>
  );
};
