import {
  fifaCoach,
  izziLolCoachUrl,
  izziValorantCoachUrl,
} from '@resources/config';

import LeaguesValColorBackground from '../assets/images/background/LeaguesColorBackground.png';
import LeaguesValCharacterBackground from '../assets/images/background/LeaguesValCharacterBackground.png';
import LeaguesWZCharacterBackground from '../assets/images/background/LeaguesWZCharacterBackground.jpg';

export const gamesEnum = Object.freeze({
  valorant: 'VALORANT',
  lol: 'LOL',
  fortnite: 'FORTNITE',
  warzone: 'WARZONE',
  fifa: 'FIFA',
  efootball: 'EFOOTBALL',
});

export const coachRedirectByGame = (game, redirect) => {
  switch (game) {
    case gamesEnum.fifa: {
      return redirect(fifaCoach);
    }
    case gamesEnum.valorant: {
      return redirect(izziValorantCoachUrl);
    }
    default:
    case gamesEnum.lol: {
      return redirect(izziLolCoachUrl);
    }
  }
};

export const getCoachPartnerByGame = (game) => {
  switch (game.toUpperCase()) {
    case gamesEnum.fifa: {
      return fifaCoach;
    }
    case gamesEnum.valorant: {
      return izziValorantCoachUrl;
    }
    default:
    case gamesEnum.lol: {
      return izziLolCoachUrl;
    }
  }
};

export const getBackgroundImageByGame = (game) => {
  switch (game?.toUpperCase()) {
    default:
    case gamesEnum.valorant: {
      return LeaguesValColorBackground;
    }
    case gamesEnum.warzone: {
      return LeaguesValColorBackground;
    }
  }
};

export const getCharacterImageByGame = (game) => {
  switch (game?.toUpperCase()) {
    default:
    case gamesEnum.valorant: {
      return LeaguesValCharacterBackground;
    }
    case gamesEnum.warzone: {
      return LeaguesWZCharacterBackground;
    }
  }
};

export const availableGames = Object.values(gamesEnum);
