import React, { useContext, useEffect } from 'react';
import { IconContext } from 'react-icons';
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import requester from '@resources/requester';
import { CreditsContext } from '@src/contexts/CreditsProvider';
import useUserSelector from '@src/store/selectors/useUserSelector';
import { Color } from '@styles/variables';
import setToast from '@utils/toast.utils';
import { creditFormatter } from '@utils/userCreditsTransactions.utils';

import { Content, Info, InfoWrapper, PlusIcon, WalletIcon } from './style';

const Credits = ({ sidebar }) => {
  const navigate = useNavigate();
  const { user } = useUserSelector();
  const { userCredits, updateUserCredits } = useContext(CreditsContext);

  const getUserCredits = async () => {
    const [error, response] = await requester(
      'GET',
      `/v1/public/credits-transactions/users/${user._id}/balance`
    );
    if (error) {
      setToast(
        'error',
        'Erro ao carregar assinatura. Por favor, tente novamente.'
      );
    } else {
      const { data } = response;
      updateUserCredits(data);
    }
  };

  useEffect(() => {
    if (!userCredits) {
      getUserCredits();
    }
  }, []);

  const handleBuyCredit = () => {
    navigate('/history?buy-credits=true');
    window.location.reload();
  };

  return (
    <Content sidebar={sidebar}>
      <WalletIcon size={17} />
      <InfoWrapper onClick={() => navigate('/history')}>
        <Info>
          <p>Créditos</p>
          <p>{creditFormatter(userCredits?.credit)}</p>
        </Info>
        <Info>
          <p>Bonus</p>
          <p>{creditFormatter(userCredits?.bonus)}</p>
        </Info>
      </InfoWrapper>
      <IconContext.Provider value={{ color: Color.primary.main }}>
        <PlusIcon onClick={() => handleBuyCredit()}>
          <BsFillPlusSquareFill />
        </PlusIcon>
      </IconContext.Provider>
    </Content>
  );
};

export default Credits;
