import React, { lazy } from 'react';

import DefaultLayout from '../layout/Default';

const Coach = lazy(() => import('../screens/Coach'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
  layout: DefaultLayout,
});

const coachesRoutes = [
  {
    path: '/coaches/:currentGame/:displayName',
    element: () => <Coach />,
  },
  {
    path: '/coaches/:currentGame',
    element: () => <Coach />,
  },
];

const mappedCoachesRoutes = coachesRoutes.map(addCommonProps);

export default mappedCoachesRoutes;
