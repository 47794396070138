import { FaTrophy } from 'react-icons/fa';

import { ReactComponent as CoachesIcon } from '@assets/icons/coaches.svg';
import { ReactComponent as CourseIcon } from '@assets/icons/course.svg';
import { ReactComponent as EvolutionIcon } from '@assets/icons/evolution.svg';
import { ReactComponent as HomeIcon } from '@assets/icons/home.svg';
import styled from 'styled-components';

import { Color, FontFamily, MediaQuery } from '../../styles/variables';

export const Footer = styled.footer`
  display: none;
  bottom: 0vh;
  background: ${Color.gray[9]};
  width: 0%;
  position: fixed;
  z-index: 2;
  min-height: 66px;
  align-items: center;

  ${({ removePl }) =>
    removePl ? 'padding-right: 21px;' : 'padding: 0px 21px;'}

  @media ${MediaQuery.MEDIUM_DESKTOP_DOWN} {
    ${({ isLogged }) => (isLogged ? 'display: flex;' : 'display: none;')}
    width: 100%;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ width }) => (width ? `${width}px;` : `90px;`)};
`;

export const MenuItemLabel = styled.div`
  font-family: ${FontFamily.POPPINS};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px;` : `12px;`)};
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  ${({ active }) =>
    active ? `color: ${Color.lime[12]};` : `color: ${Color.gray[1]};`}

  ${({ active }) =>
    active ? `text-shadow: 0px 1px 2px rgba(111, 255, 1, 0.33);` : ``}
`;

export const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const StyledHomeIcon = styled(HomeIcon)`
  width: 18px;
  height: 18px;

  & path {
    stroke: ${({ active }) =>
      active ? `${Color.lime[12]}` : `${Color.gray[1]}`};
    color: ${({ active }) =>
      active ? `${Color.lime[12]}` : `${Color.gray[1]}`};
  }
`;

export const StyledEvolutionIcon = styled(EvolutionIcon)`
  width: 18px;
  height: 18px;

  & path {
    stroke: ${({ active }) =>
      active ? `${Color.lime[12]}` : `${Color.gray[1]}`};
    color: ${({ active }) =>
      active ? `${Color.lime[12]}` : `${Color.gray[1]}`};
  }
`;

export const StyledCourseIcon = styled(CourseIcon)`
  width: 18px;
  height: 18px;

  & path {
    stroke: ${({ active }) =>
      active ? `${Color.lime[12]}` : `${Color.gray[1]}`};
    color: ${({ active }) =>
      active ? `${Color.lime[12]}` : `${Color.gray[1]}`};
  }
`;

export const StyledCoachesIcon = styled(CoachesIcon)`
  width: 18px;
  height: 18px;

  & path {
    stroke: ${({ active }) =>
      active ? `${Color.lime[12]}` : `${Color.gray[1]}`};
    color: ${({ active }) =>
      active ? `${Color.lime[12]}` : `${Color.gray[1]}`};
  }
`;

export const StyledLeagueIcon = styled(FaTrophy)`
  width: 18px;
  height: 18px;

  & path {
    stroke: ${({ active }) =>
      active ? `${Color.lime[12]}` : `${Color.gray[1]}`};
    color: ${({ active }) =>
      active ? `${Color.lime[12]}` : `${Color.gray[1]}`};
  }
`;
