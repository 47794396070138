import { TbWallet } from 'react-icons/tb';

import { Color, MediaQuery } from '@styles/variables';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ sidebar }) => (sidebar ? '0' : '0 10px 0 35px')};
  align-items: center;
  color: ${Color.gray[6]};
  min-width: max-content;

  @media ${MediaQuery.MOBILE} {
    margin-bottom: 15px;
  }
`;

export const InfoWrapper = styled.button`
  display: flex;
  flex-direction: column;
  background: none;
  color: inherit;
  border: none;
  padding: 0 2.5px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

export const Info = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  font-size: 12px;
  justify-content: space-between;
`;

export const PlusIcon = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 7.5px;
`;

export const WalletIcon = styled(TbWallet)`
  margin-right: 7.5px;
`;
