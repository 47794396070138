import React, { lazy } from 'react';

const RankingFire = lazy(() => import('../screens/RankingFire'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
});

const eventsRoutes = [
  { path: '/ranking-fire', element: () => <RankingFire /> },
];

const mappedEventsRoutes = eventsRoutes.map(addCommonProps);

export default mappedEventsRoutes;
