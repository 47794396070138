import React from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { Color, FontFamily, FontWeight } from '@styles/variables';

const useStyles = makeStyles({
  root: {
    padding: '4px 16px',
    backgroundColor: 'transparent',
    border: ({ color }) => `solid 1px ${color || Color.primary.main}`,
    color: ({ color }) => color || Color.primary.main,
    borderRadius: '4px',
    cursor: ({ disabled }) => (disabled ? 'initial' : 'pointer'),
    boxSizing: 'border-box',
    fontFamily: FontFamily.POPPINS,
    fontSize: 14,
    fontWeight: FontWeight.REGULAR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'start',
    width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
});

const OutlinedButton = ({
  onClick,
  title,
  iconStart,
  iconEnd,
  styles = {},
  color,
  fullWidth,
  disabled,
}) => {
  const classes = useStyles({ color, fullWidth, disabled });

  return (
    <button
      type="button"
      className={classes.root}
      onClick={onClick}
      style={styles}
      disabled={disabled}
    >
      {iconStart && (
        <Box className={classes.icon} mr={2}>
          {iconStart}
        </Box>
      )}
      {title}
      {iconEnd && (
        <Box className={classes.icon} ml={2}>
          {iconEnd}
        </Box>
      )}
    </button>
  );
};

export default OutlinedButton;
