import styled from 'styled-components';

import { Color, FontFamily } from '../../styles/variables';

export const Header = styled.section`
  width: 100%;
  background: #262626;
  padding: 0px 21px;
  position: fixed;
  z-index: 2;
  display: flex;
  min-height: 61px;
  align-items: center;
`;

export const SignUpButton = styled.button`
  display: flex;
  width: 170px;
  height: 30px;
  background: ${Color.lime[12]};
  box-shadow: 0px 0px 8px rgba(111, 255, 1, 0.4);
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  text-align: center;
  font-family: ${FontFamily.POPPINS};
  font-size: 14px;
  color: ${Color.gray[9]};
`;

export const SignInButton = styled.div`
  font-size: 14px;
  font-family: ${FontFamily.POPPINS};
  color: ${Color.gray[2]};
  cursor: pointer;
  margin: 0px 10px 0px 20px;
`;

export const LiveButtonContent = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
