const izziApiUrl = process.env.REACT_APP_IZZI_API_URL;
const izziStorageUrl = process.env.REACT_APP_IZZI_STORAGE_URL;
const izziDiscordUrl = process.env.REACT_APP_IZZI_DISCORD_URL;
const fortniteDiscordUrl = process.env.REACT_APP_FORTNITE_DISCORD_URL;
const warzoneDiscordUrl = process.env.REACT_APP_WARZONE_DISCORD_URL;
const efootballDiscordUrl = process.env.REACT_APP_EFOOTBALL_DISCORD_URL;
const fifaCoachUrl = process.env.REACT_APP_FIFA_COACH_URL;
const youtubeApiUrl = process.env.REACT_APP_YOUTUBE_API_URL;
const youtubeApiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
const vimeoApiUrl = process.env.REACT_APP_VIMEO_API_URL;
const vimeoToken = process.env.REACT_APP_VIMEO_API_TOKEN;
const lolPatchVersion = process.env.REACT_APP_LOL_PATCH;
const lolDataDragonApiUrl = process.env.REACT_APP_LOL_DATA_DRAGON_API_URL;
const lolDataDragonCdnUrl = process.env.REACT_APP_LOL_DATA_DRAGON_CDN_URL;
const pagarmeEncryptionKey = process.env.REACT_APP_PAGARME_ENCRYPTION_KEY;
const mixpanelTokenStaging = process.env.REACT_APP_MIXPANEL_TOKEN_STAGING;
const mixpanelTokenProd = process.env.REACT_APP_MIXPANEL_TOKEN_PROD;
const environment = process.env.NODE_ENV;
const gamesIndicationFormsUrl = process.env.REACT_APP_GAMES_INDICATION_FORMS;
const userTermsUrl = process.env.REACT_APP_USER_TERMS;
const izziAppEnv = process.env.REACT_APP_ENVIRONMENT;
const izziLp = process.env.REACT_APP_LANDING_PAGE;
const izziWhatsappEnv = process.env.REACT_APP_WHATSAPP;
const izziOldUrl = process.env.REACT_APP_IZZI_OLD_URL;
const lolRankingRolesUrl = process.env.REACT_APP_LOL_RANKING_ROLES_URL;
const maintenanceModeConfig =
  process.env.REACT_APP_MAINTENANCE_MODE === 'true' ||
  process.env.REACT_APP_MAINTENANCE_MODE === true;
const izziValorantCoach = process.env.REACT_APP_VALORANT_COACH_URL;
const izziLolCoach = process.env.REACT_APP_LOL_COACH_URL;
const izziCreditsOfferId = process.env.REACT_APP_CREDITS_OFFER_ID;
const sellerIzziIdString = process.env.REACT_APP_IZZI_SELLER_ID;
const valorantPromotionalPlanIdString =
  process.env.REACT_APP_IZZI_VALORANT_PROMOTIONAL_PLAN_ID;
const warzonePromotionalPlanIdString =
  process.env.REACT_APP_IZZI_WARZONE_PROMOTIONAL_PLAN_ID;

export const izzi = izziApiUrl;
export const izziOld = izziOldUrl;
export const izziApp = izziAppEnv;
export const izziWhatsapp = izziWhatsappEnv;
export const izziLpUrl = izziLp;
export const izziStorage = izziStorageUrl;
export const izziDiscord = izziDiscordUrl;
export const fortniteDiscord = fortniteDiscordUrl;
export const warzoneDiscord = warzoneDiscordUrl;
export const efootballDiscord = efootballDiscordUrl;
export const fifaCoach = fifaCoachUrl;
export const lolPatch = lolPatchVersion;
export const pagarmeKey = pagarmeEncryptionKey;
export const creditsOfferId = izziCreditsOfferId;
export const sellerIzziId = sellerIzziIdString;
export const env = environment;
export const gamesIndicationForms = gamesIndicationFormsUrl;
export const regulation = lolRankingRolesUrl;
export const userTermsLink = userTermsUrl;
export const pageHotmartLol = process.env.REACT_APP_HOTMART_SUBSCRIPTION_LOL;
export const pageHotmartFifa = process.env.REACT_APP_HOTMART_SUBSCRIPTION_FIFA;
export const pageHotmartWarzone =
  process.env.REACT_APP_HOTMART_SUBSCRIPTION_WARZONE;
export const pageHotmartValorant =
  process.env.REACT_APP_HOTMART_SUBSCRIPTION_VALORANT;
export const pageHotmartFortnite =
  process.env.REACT_APP_HOTMART_SUBSCRIPTION_FORTNITE;
export const pageHotmartEfootball =
  process.env.REACT_APP_HOTMART_SUBSCRIPTION_EFOOTBALL;
export const mixpanelToken =
  env === 'production' && mixpanelTokenProd
    ? mixpanelTokenProd
    : mixpanelTokenStaging;

export const signupOAuthDiscord =
  process.env.REACT_APP_DISCORD_SIGNUP_OAUTH_AUTHORIZE_URL;

export const signinOAuthDiscord =
  process.env.REACT_APP_DISCORD_SIGNIN_OAUTH_AUTHORIZE_URL;

export const linkOAuthDiscord =
  process.env.REACT_APP_DISCORD_LINK_OAUTH_AUTHORIZE_URL;

export const signupOAuthTwitch =
  process.env.REACT_APP_TWITCH_SIGNUP_OAUTH_AUTHORIZE_URL;

export const signinOAuthTwitch =
  process.env.REACT_APP_TWITCH_SIGNIN_OAUTH_AUTHORIZE_URL;

export const linkOAuthTwitch =
  process.env.REACT_APP_TWITCH_LINK_OAUTH_AUTHORIZE_URL;

export const linkOAuthRiot =
  process.env.REACT_APP_RIOT_LINK_OAUTH_AUTHORIZE_URL;

export const vendors = {
  youtube: {
    url: youtubeApiUrl,
    key: youtubeApiKey,
  },
  vimeo: {
    url: vimeoApiUrl,
    token: vimeoToken,
  },
  lolDataDragonApi: {
    url: lolDataDragonApiUrl,
  },
  lolDataDragonCdn: {
    url: lolDataDragonCdnUrl,
  },
};

export const maintenanceMode = maintenanceModeConfig;
export const izziValorantCoachUrl = izziValorantCoach;
export const izziLolCoachUrl = izziLolCoach;

export const coachByGame = {
  LOL: izziLolCoachUrl,
  VALORANT: izziValorantCoachUrl,
  FIFA: fifaCoachUrl,
};

export const valorantPromotionalPlanId = valorantPromotionalPlanIdString;
export const warzonePromotionalPlanId = warzonePromotionalPlanIdString;
