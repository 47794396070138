import React, { useContext } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useNavigate } from 'react-router-dom';

import OutlinedButton from '@components/Button/OutlinedButton';
import Credits from '@components/Credits';
import useCurrentGame from '@hooks/localStorage/useCurrentGame';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosSharp from '@material-ui/icons/ArrowForwardIosSharp';
import Box from '@mui/material/Box';
import {
  fifaCoach,
  izziLolCoachUrl,
  izziValorantCoachUrl,
} from '@resources/config';
import { CreditsContext } from '@src/contexts/CreditsProvider';
import { GamesContext } from '@src/contexts/GamesProvider';
import { Color } from '@styles/variables';

import logoIzzi from '../../assets/izzi/izzi-icon.png';
import GreenDiscord from '../../assets/svgs/green-discord.svg';
import useGlobal from '../../store';
import SubscribeButton from '../SubscribeButton';
import UserLogo from '../UserLogo';
import './Sidebar.css';

const useStyles = makeStyles(() => ({
  userIcon: {
    marginTop: '16px',
    marginBottom: '14px',
  },
  logo: {
    width: 40,
    cursor: 'pointer',
  },
  menuItem: {
    fontSize: '20px',
  },
  gameNameContainer: {
    borderBottom: `1px solid ${Color.gray[8]}`,
    width: '100%',
    paddingBottom: '12px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginBottom: '56px',
    '&:focus': {
      outline: '2px solid transparent',
      outlineOffset: 2,
    },
  },
  gameLabel: {
    fontSize: '14px',
    color: Color.gray[7],
    marginBottom: '6px',
  },
  arrowIcon: {
    marginLeft: '16px',
  },
  currentGame: {
    fontSize: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  gameIcon: {
    width: '26px',
    height: '26px',
  },
}));

const menuStyles = {
  bmBurgerBars: {
    background: Color.gray[1],
  },
  bmBurgerBarsHover: {
    opacity: 0.7,
  },
  bmCross: {
    background: Color.gray[1],
  },
  bmCrossButton: {
    marginTop: '8px',
    marginRight: '8px',
  },
  bmItemList: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  bmItem: {
    marginTop: '0px',
    marginBottom: '30px',
    fontSize: '20px',
  },
  bmMenu: {
    backgroundColor: Color.gray[9],
  },
  bmMenuWrap: {
    width: '80%',
    maxWidth: '400px',
    top: 0,
  },
  bmOverlay: {
    backgroundColor: Color.gray[8],
    opacity: 0.2,
    top: 1,
    bottom: 1,
    left: 1,
    right: 1,
  },
};

const toggleMenu = ({ isOpen }) => {
  const menuWrap = document.querySelector('.bm-menu-wrap');
  if (isOpen) {
    menuWrap.setAttribute('aria-hidden', false);
  } else {
    menuWrap.setAttribute('aria-hidden', true);
  }
};

const Sidebar = ({ user, hasSubscription, currentGame }) => {
  const classes = useStyles();
  const {
    isEfootball,
    isLol,
    isValorant,
    isFifa,
    isWarzone,
    discordCurrentGame,
  } = useCurrentGame();
  const [, globalActions] = useGlobal();
  const navigate = useNavigate();
  const { updateUserCredits } = useContext(CreditsContext);
  const { games } = useContext(GamesContext);

  const { logOut } = globalActions;

  const handleLeagueClickMenuItem = (e) => {
    e.preventDefault();
    toggleMenu({ isOpen: false });
    navigate(`/leagues/${currentGame.toUpperCase()}`);
  };

  const handleClickLogout = (e) => {
    e.preventDefault();
    updateUserCredits(null);
    logOut(navigate);
  };

  const renderCurrentGame = () => (
    <Box className={classes.currentGame}>
      {games?.map((game) => {
        if (game.codeName.toUpperCase() === currentGame.toUpperCase()) {
          return (
            <>
              <img
                className={classes.gameIcon}
                src={game.logo}
                alt="logo do jogo"
              />
              {game.name}
            </>
          );
        }
        return <></>;
      })}
    </Box>
  );

  const renderMenuItem = (label, href, onClick) => (
    <a
      className={classes.menuItem}
      href={href}
      onClick={onClick}
      style={user ? {} : { fontSize: 16 }}
    >
      {label} <ArrowForwardIosSharp className={classes.arrowIcon} />
    </a>
  );

  return (
    <>
      {user ? (
        <Menu styles={menuStyles} onStateChange={toggleMenu} right>
          <UserLogo classes={classes.userIcon} size={50} />
          <Credits sidebar />
          <Box
            className={classes.gameNameContainer}
            style={{ display: 'flex' }}
          >
            <Box className={classes.gameLabel}>Jogo</Box>
            {renderCurrentGame()}
          </Box>

          {!hasSubscription && (
            <SubscribeButton margin currentGame={currentGame} />
          )}

          {isValorant &&
            renderMenuItem('Torneios', '/', handleLeagueClickMenuItem)}
          {!isWarzone &&
            !isEfootball &&
            renderMenuItem('Biblioteca', '/library')}
          {isFifa && renderMenuItem('Análise de Times', fifaCoach)}
          {isValorant &&
            renderMenuItem('Análise Gameplay', izziValorantCoachUrl)}
          {isLol && renderMenuItem('Análise Gameplay', izziLolCoachUrl)}
          {user &&
            user.role !== 'PLAYER' &&
            isLol &&
            renderMenuItem('Meus Alunos', '/my-students')}
          {user &&
            user.role !== 'PLAYER' &&
            renderMenuItem('Minhas Vendas', '/my-sales')}
          {renderMenuItem('Meu Perfil', '/profile')}
          {renderMenuItem('Meus Objetivos', `/gaming/${currentGame}`)}
          {renderMenuItem('Assinaturas', '/subscriptions')}
          {renderMenuItem('Minhas Análises', '/purchases')}
          {renderMenuItem('Sair', '/', handleClickLogout)}

          <OutlinedButton
            styles={{ padding: '11px 16px', marginTop: 16 }}
            iconStart={<img src={GreenDiscord} alt="discord" />}
            onClick={() => {
              window.open(discordCurrentGame, '_blank');
            }}
            title={`${
              !isLol && !isValorant
                ? 'Acesse o Discord'
                : 'Acesse nosso Discord'
            }`}
          />
        </Menu>
      ) : (
        <Menu styles={menuStyles} onStateChange={toggleMenu} right>
          <img
            role="presentation"
            src={logoIzzi}
            alt="logo Izzi"
            className={classes.logo}
          />
          {renderMenuItem('Cadastre-se na IZZI', '/signup')}
          {renderMenuItem('Entrar', '/signin')}

          <OutlinedButton
            styles={{ padding: '11px 16px', marginTop: 16, fontSize: 16 }}
            iconStart={<img src={GreenDiscord} alt="discord" />}
            onClick={() => {
              window.open(discordCurrentGame, '_blank');
            }}
            title={`${
              !isLol && !isValorant
                ? 'Acesse o Discord'
                : 'Acesse nosso Discord'
            }`}
          />
        </Menu>
      )}
    </>
  );
};

export default Sidebar;
