export const getActiveSubscriptions = (userObj) => {
  const date = new Date();

  const subscriptions =
    userObj?.subscription?.filter(
      (s) => s.status === 'ACTIVE' && date <= new Date(s.expiresIn)
    ) ?? [];

  return subscriptions;
};

export const getMainGame = (subscriptions) => {
  if (!subscriptions || subscriptions?.length === 0) {
    return 'LOL';
  }
  return subscriptions[0].game;
};

export const modalDescriptionLOL = [
  {
    id: 0,
    description: 'Cursos Completos de Todas as Lanes com Campeões do CBLOL',
  },
  {
    id: 1,
    description: 'Sessão Individual com Coach Grão Mestre toda Semana',
  },
  {
    id: 2,
    description: 'Canal Exclusivo com Coach 24h no Discord',
  },
  {
    id: 3,
    description: 'Campeonatos e Rankings Exclusivos com Premiação',
  },
];

export const modalDescriptionVALORANT = [
  {
    id: 0,
    description:
      'Curso VALORANT M.E.T.A com conteúdos completos de Mira, Estilo de Jogo, Tática, Adaptação',
  },
  {
    id: 1,
    description: 'Análises Semanais da sua Gameplay com o Coach F0ky',
  },
  {
    id: 2,
    description: 'Aulões teóricos semanais com conteúdos diversos',
  },
];

export const modalDescriptionFIFA = [
  {
    id: 0,
    description: 'Curso completo de Trade',
  },
  {
    id: 1,
    description: 'Curso completo de Gameplay',
  },
  {
    id: 2,
    description: 'Comunidade exclusiva do Elite Milionária',
  },
  {
    id: 3,
    description: 'Desconto EXCLUSIVO em Grupo de Trade',
  },
];

export const modalDescriptionWARZONE = [
  {
    id: 0,
    description: 'Conteúdos exclusivos para melhorar sua gameplay',
  },
  {
    id: 1,
    description: 'Lives privadas exclusivas com os assinantes',
  },
  {
    id: 2,
    description: 'Comunidade exclusiva do 9ops',
  },
  {
    id: 3,
    description: 'Partidas da comunidade analisadas pelo ninext',
  },
];
