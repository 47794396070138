import React, { Suspense, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import useSection from '@src/hooks/localStorage/useSection';
import useGlobal from '@src/store';

import { version } from '../../package.json';
import Loader from '../components/ui/Loader.component';
import { maintenanceMode } from '../resources/config';
import requester from '../resources/requester';
import { mapRouteObject } from '../utils/routes.utils';
import setToast from '../utils/toast.utils';
import analysisRoutes from './analysisRoutes';
import authRoutes from './authenticationRoutes';
import buildRoutes from './buildRoutes';
import classRoutes from './classRoutes';
import coachesRoutes from './coachesRoutes';
import coachScheduleRoutes from './coachScheduleRoutes';
import dashboardRoutes from './dashboardRoutes';
import eventsRoutes from './eventsRoutes';
import leagueRoutes from './leagueRoutes';
import liveRoutes from './liveRoutes';
import maintenanceRoutes from './maintenanceRoutes';
import myStudentsRoutes from './myStudentsRoutes';
import profileRoutes from './profileRoutes';
import rankingRoutes from './rankingRoutes';

const izziVersion = `izzi-gaming-${version}`;

const routeObjects = [
  ...authRoutes,
  ...classRoutes,
  ...coachesRoutes,
  ...coachScheduleRoutes,
  ...dashboardRoutes,
  ...analysisRoutes,
  ...rankingRoutes,
  ...leagueRoutes,
  ...profileRoutes,
  ...myStudentsRoutes,
  ...liveRoutes,
  ...maintenanceRoutes,
  ...buildRoutes,
  ...eventsRoutes,
];
const mappedRoutes = routeObjects.map(mapRouteObject);

const AppRoutes = () => {
  const navigate = useNavigate();
  const byPassRoutes = [
    '',
    'signin',
    'signup',
    'forgot-password',
    'reset-password',
    'coaches',
    'ranking',
    'ranking-fire',
    'izzi-build',
    'verify-account',
    'leagues',
    'past-leagues',
  ];
  const token = localStorage.getItem(`${izziVersion}-token`);
  const path = window.location.pathname;
  const { storeTokenId, storeUserId, getStoredUserId } = useSection();
  const [_, actions] = useGlobal();
  const [loadingUser, setLoadingUser] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (maintenanceMode) {
      navigate('/maintenance');
    }
  }, []);

  useEffect(() => {
    if (!maintenanceMode) {
      const fetchData = async () => {
        const url = new URL(window.location.href);
        const urlToken = url.searchParams.get('token');
        const resignin = url.searchParams.get('resignin');
        const email = url.searchParams.get('email');

        if (!token && urlToken && resignin) {
          storeTokenId(urlToken);
          const [, response] = await requester(
            'GET',
            `/v1/public/auth/resignin`
          );
          if (response.status === 200) {
            const { data } = response;
            storeTokenId(data.token);
            storeUserId(data.user.id);

            window.history.replaceState(null, null, window.location.pathname);
            window.location.reload();
          }
        } else if (path.split('/')[1] !== 'signup' && !email) {
          window.history.replaceState(null, null, window.location.pathname);

          const isPublicRoute = byPassRoutes.includes(path.split('/')[1]);
          if (!token && !isPublicRoute) {
            navigate('/signin');
            setToast('error', 'Login necessário');
          }

          if (!token && !path.split('/')[1]) {
            navigate('/signin');
          } else if (!path.split('/')[1]) {
            navigate('/home');
          }
        }
      };

      fetchData();
    }
  }, []);

  const fetchUserData = async (userId) => {
    setLoadingUser(true);
    const [_err, response] = await requester(
      'GET',
      `/v1/public/users/${userId}`
    );

    if (response?.status === 200) actions.setUserContext('root', response.data);

    setLoadingUser(false);
  };

  useEffect(() => {
    if (!maintenanceMode) {
      const storedUserId = getStoredUserId();

      if (storedUserId) {
        fetchUserData(storedUserId);
      } else {
        setLoadingUser(false);
      }
    } else {
      setLoadingUser(false);
    }
  }, []);

  if (loadingUser) return <Loader fallback />;

  return (
    <Suspense fallback={<Loader fallback />}>
      <Routes>
        {mappedRoutes}
        <Route
          path="*"
          element={
            token ? (
              <Navigate to="/home" replace />
            ) : (
              <Navigate to="/signin" replace />
            )
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
