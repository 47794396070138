import { dateFormatter, isToday, isYesterday } from './date.utils';

const positiveTypes = [
  'AWARD',
  'CASH_IN',
  'SALE',
  'REDEEM',
  'PURCHASE_REFUNDED',
];
const negativeTypes = ['PURCHASE', 'CASH_OUT', 'REFUNDED'];

export const getMoneyPolarityByTransactionType = (type, value) => {
  if (positiveTypes.includes(type)) {
    return value;
  }
  if (negativeTypes.includes(type)) {
    return -value;
  }
  return 0;
};

export const getTransactionTypeText = (type, purchaseType) => {
  switch (type) {
    case 'AWARD': {
      return 'Resgate de premiação';
    }
    case 'CASH_IN': {
      return 'Compra de créditos';
    }
    case 'SALE': {
      switch (purchaseType) {
        case 'ASYNC_ANALYSIS': {
          return 'Venda de Análise';
        }
        default: {
          return 'Venda';
        }
      }
    }
    case 'REDEEM': {
      return 'Resgate de créditos';
    }
    case 'PURCHASE': {
      switch (purchaseType) {
        case 'LEAGUE': {
          return 'Compra de ingresso para o torneio';
        }
        case 'ASYNC_ANALYSIS': {
          return 'Compra de análise de gameplay';
        }
        case 'SUBSCRIPTION': {
          return 'Compra de assinatura';
        }
        default: {
          return 'Compra de produto IZZI';
        }
      }
    }
    case 'CASH_OUT': {
      return 'Saque de créditos';
    }
    case 'REFUNDED': {
      return 'Reembolso';
    }
    case 'PURCHASE_REFUNDED': {
      switch (purchaseType) {
        case 'LEAGUE': {
          return 'Reembolso do torneio';
        }
        default: {
          return 'Reembolso de um produto IZZI';
        }
      }
    }
    default: {
      return '';
    }
  }
};

export const creditFormatter = (currency) => {
  if (Number.isNaN(parseFloat(currency))) {
    return 'R$ 0,00';
  }
  const value = Number(currency / 100).toFixed(2);
  return `R$ ${value.replace('.', ',')}`;
};

export const getCreditType = (creditType) => {
  switch (creditType) {
    case 'NORMAL': {
      return 'CRÉDITO';
    }
    case 'BONUS': {
      return 'BÔNUS';
    }
    default: {
      return '';
    }
  }
};

export const getTransactionInfos = (transaction) => {
  const today = new Date();
  const isPositive =
    getMoneyPolarityByTransactionType(transaction.type, transaction.value) >= 0;
  const typeText = getTransactionTypeText(
    transaction.type,
    transaction.purchaseType
  );
  const valueText = creditFormatter(transaction.value);
  const creditType = getCreditType(transaction.creditType);
  const transactionDate = dateFormatter(transaction.createdAt);
  let transactionDateText = '';
  if (
    isToday(new Date(transaction.createdAt)) ||
    isYesterday(new Date(transaction.createdAt))
  ) {
    transactionDateText = `Realizada ${transactionDate}`;
  } else {
    transactionDateText = `Realizada dia ${transactionDate}`;
  }
  let availableDate = '';
  let availableDateText = '';
  if (new Date(transaction.availableAt).getFullYear() === 2999) {
    availableDate = 'Indisponível para saque';
  } else {
    availableDate = transaction.availableAt
      ? dateFormatter(transaction.availableAt)
      : null;
    availableDateText = 'Liberado para saque em';
  }
  const expiresIn = transaction.expiresIn
    ? dateFormatter(transaction.expiresIn)
    : null;
  let expiresInText = '';
  if (
    isToday(new Date(transaction.expiresIn)) ||
    new Date(transaction.expiresIn) > today
  ) {
    expiresInText = `Expira`;
  } else {
    expiresInText = `Expirou em`;
  }

  return {
    isPositive,
    typeText,
    valueText,
    creditType,
    transactionDateText,
    availableDate,
    availableDateText,
    expiresIn,
    expiresInText,
    leagueId: transaction.leagueId,
  };
};
