import React, { lazy } from 'react';

import DefaultLayout from '../layout/Default';

const Live = lazy(() => import('../screens/Live'));

const addCommonProps = (route) => ({
  ...route,
  exact: true,
  layout: DefaultLayout,
});

const eventsRoutes = [{ path: '/live', element: () => <Live /> }];

const mappedEventsRoutes = eventsRoutes.map(addCommonProps);

export default mappedEventsRoutes;
