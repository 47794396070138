import React, { useEffect, useState } from 'react';

import useCurrentGame from '@hooks/localStorage/useCurrentGame';

import { Container, Description } from './style';

const Footer = () => {
  const { isLol, isValorant, isFifa, isWarzone } = useCurrentGame();
  const [text, setText] = useState();

  useEffect(() => {
    if (isLol || isValorant) {
      setText(
        'A IZZI Gaming não é endossada pela Riot Games e não reflete as visões ou opiniões da Riot Games ou de qualquer pessoa oficialmente envolvida na produção ou gerenciamento de propriedades da Riot Games. Riot Games e todas as propriedades associadas são marcas comerciais ou marcas registradas da Riot Games, Inc.'
      );
    } else if (isFifa) {
      setText(
        'FIFA 23 e Ultimate Team são marcas registradas da EA Sports. Marcas registradas são propriedade dos seus respectivos donos. As artes e os materiais do jogo são de direitos autorais da EA Sports. A EA Sports não é responsável e não endossa esse site e o seu conteúdo.'
      );
    } else if (isWarzone) {
      setText(
        'Warzone e Call of Duty são marcas registradas da Activision. Marcas registradas são propriedade dos seus respectivos donos. As artes e os materiais do jogo são de direitos autorais da Activision. A Activision não é responsável e não endossa esse site e o seu conteúdo.'
      );
    }
  }, []);

  return (
    <Container>
      <Description>{text}</Description>
    </Container>
  );
};

export default Footer;
