import { version } from '../../package.json';
import { availableGames } from '../utils/games.utils';
import {
  getActiveSubscriptions,
  getMainGame,
} from '../utils/subscription.utils';

const izziVersion = `izzi-gaming-${version}`;

export const setAuthAndUser = (
  store,
  token = null,
  user = null,
  game = 'WARZONE'
) => {
  if (token) localStorage.setItem(`${izziVersion}-token`, token);
  if (user) {
    store.actions.setUserContext('root', user);
    localStorage.setItem(`${izziVersion}-user-id`, user._id);

    const activeSubscriptions = getActiveSubscriptions(user);
    if (activeSubscriptions.length) {
      game = getMainGame(activeSubscriptions);
    }
  }

  return game;
};

export const cleanStorage = (store) => {
  store.actions.cleanUser();

  Object.entries(localStorage).forEach(([key]) => {
    const gameKey = new RegExp(availableGames.join('|')).test(key);

    if (key.includes(izziVersion) || gameKey) {
      delete localStorage[key];
    }
  });

  delete localStorage.dateDiagnostic;
};

export const addInStore = (store, context, item) => {
  const contextStore = {
    ...store.state.content,
    options: [...store.state[context].options, item],
  };
  store.setState({ ...store.state, [context]: contextStore });
};

export const removeFromStore = (store, context, itemId) => {
  const itemIndex = store.state[context].options.findIndex(
    (i) => i.id === itemId
  );
  const contextStore = {
    ...store.state[context],
    options: [
      ...store.state[context].options.slice(0, itemIndex),
      ...store.state[context].options.slice(itemIndex + 1),
    ],
  };
  store.setState({ ...store.state, [context]: contextStore });
};

export const updateInStore = (store, context, item) => {
  const itemIndex = store.state[context].options.findIndex(
    (i) => i.id === item.id
  );
  const contextStore = {
    ...store.state[context],
    options: [
      ...store.state[context].options.slice(0, itemIndex),
      item,
      ...store.state[context].options.slice(itemIndex + 1),
    ],
  };
  store.setState({ ...store.state, [context]: contextStore });
};

export const setInStore = (store, context, property, value) => {
  const contextStore = {
    ...store.state[context],
    [property]: value,
  };
  store.setState({ ...store.state, [context]: contextStore });
};
