import styled from 'styled-components';

import { FontFamily, FontWeight, MediaQuery } from '../../styles/variables';

export const Container = styled.footer`
  padding: 30px 30px;
  background-color: #0e0e0e;

  @media ${MediaQuery.LARGE_TABLET_WIDTH_DOWN} {
    padding: 0px 20px 86px 20px;
  }
`;

export const Description = styled.p`
  font-size: 12px;
  font-weight: ${FontWeight.BOLD};
  font-family: ${FontFamily.RALEWAY};
  color: #595959;
  text-align: center;
  opacity: 0.5;
  @media ${MediaQuery.MINI_MOBILE} {
    font-size: 9px;
  }
`;
