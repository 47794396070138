import React from 'react';

import useHotmart from '@src/hooks/useHotmart';
import Tracker from '@utils/tracker.utils';

import { Container, Title } from './style';

const SubscribeButton = ({
  margin,
  left,
  right,
  currentGame,
  customLabel,
  screen,
}) => {
  const { redirectToHotmart } = useHotmart();

  const clickButton = () => {
    Tracker({
      eventName: `Click Subscription ${screen ?? 'Menu'}`,
      params: {
        game: currentGame,
      },
    });

    redirectToHotmart('home', 'menu');
  };
  return (
    <Container margin={margin} left={left} right={right} onClick={clickButton}>
      <Title>{customLabel ?? 'Seja Assinante'}</Title>
    </Container>
  );
};

export default SubscribeButton;
